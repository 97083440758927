<template>
  <div>
    <b-modal
      id="add-exchange-truck"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddNew1')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="shownReq"
    >
      <div>
        <b-card no-body>
          <b-tabs
            v-model="tabIndex"
            small
            card
          >
            <b-tab
              :title="$t('ExchangeEmployeesSource')"
            >
              <template #title>
                <strong style="color: #0071AE">{{ $t("ExchangeEmployeesSource") }}</strong>
              </template>
              <!-- @click="clearDestinationInputs" -->
              <b-form>
                <b-form-group
                  id="input-group-9"
                  :label="$t('ExchangeEmployeesSourceStore')"
                  label-for="input-9"
                  style="width: 50%;"
                >
                  <vue-select
                    v-model="store"
                    required
                    :options="getFiliales"
                    label="storeName"
                    :value="storeGLN"
                    :placeholder="$t('Select')"
                    aria-describedby="input-1-live-feedback"
                  />
                  <span
                    v-show="formSubmitted && !$v.store.required"
                    style="color: red"
                  >{{ $t("AddNew44") }}</span>
                </b-form-group>
                <pre />
                <div style="display: flex; width: 100%; margin-top: 10px;">
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="isCheckedToday"
                    name="checkbox-2"
                    :value="true"
                    :unchecked-value="false"
                    @input="setToday(isCheckedToday)"
                  >
                    {{ $t('Today') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="isChecked"
                    name="checkbox-1"
                    :value="true"
                    :disabled="!time1"
                    :unchecked-value="false"
                    @input="setDefaultTime(isChecked)"
                  >
                    {{ $t('AllDay') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="isCheckedMorning"
                    name="checkbox-2"
                    :value="true"
                    :unchecked-value="false"
                    @input="setMorning(isCheckedMorning)"
                  >
                    {{ $t('Morning') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="isCheckedAfternoon"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    @input="setAfternoon(isCheckedAfternoon)"
                  >
                    {{ $t('Afternoon') }}
                  </b-form-checkbox>
                </div>
                <div style="display: flex; width: 100%; gap: 2%;">
                  <b-form-group
                    id="input-group-9"
                    :label="$t('ExchangeEmployeesStartingDate')"
                    label-for="input-9"
                  >
                    <date-picker
                      v-model="time1"
                      type="datetime"
                      value-type="format"
                      :placeholder="$t('Select')"
                      :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
                      style="width: 100%;"
                      :lang="lang"
                    />
                    <span
                      v-show="formSubmitted && !$v.time1.required"
                      style="color: red"
                    >{{ $t("AddNew33") }}</span>
                  </b-form-group>
                  <b-form-group
                    id="input-group-9"
                    :label="$t('ExchangeEmployeesEndingDate')"
                    label-for="input-9"
                  >
                    <date-picker
                      v-model="time2"
                      type="datetime"
                      value-type="format"
                      :placeholder="$t('Select')"
                      :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
                      style="width: 100%;"
                      :lang="lang"
                    />
                    <span
                      v-show="formSubmitted && !$v.time2.required"
                      style="color: red"
                    >{{ $t("AddNew33") }}</span>
                  </b-form-group>
                </div>
              </b-form></b-tab>
            <b-tab
              :title="$t('AddNew7')"
              :disabled="formSubmitted || !$v.store.required || !$v.time1.required || !$v.time2.required "
              @click="getFreeRegularTrucks()"
            >
              <template #title>
                <strong style="color: #0071AE">{{ $t("AddNew7") }}</strong>
              </template>
              <div
                class="d-flex"
              >
                <b-form-group
                  style="width: 100%"
                  :label="$t('ExchangeTrucksFreeSS')"
                  label-for="input-25"
                >
                  <!-- :value="truckId" -->
                  <vue-select
                    id="input-8"
                    v-model="truckSelected"
                    :options="getFreeRegularTrucksByStore"
                    :placeholder="$t('Select')"
                    label="registrationPlate"
                  />
                  <span
                    v-show="formSubmitted && !$v.truckSelected.required"
                    style="color: red"
                  >{{ $t("AddNew45") }}</span>
                </b-form-group>
              </div>
              <div
                class="d-flex"
              >
                <b-form-group
                  style="width: 100%"
                  :label="checked ? 'Destination Employee' : this.$t('ExchangeDestinationStore')"
                  label-for="input-25"
                >
                  <vue-select
                    v-model="destination"
                    required
                    :options="checked ? getFreePrivateUsers : destinationOptions"
                    :label="checked ? 'userFullName' : 'storeName'"
                    :value="checked ? userId: storeId"
                    :placeholder="$t('Select')"
                    aria-describedby="input-1-live-feedback"
                  />
                  <span
                    v-show="formSubmitted && !$v.destination.required"
                    style="color: red"
                  >{{ $t("AddNew44") }}</span>
                  <br v-if="formSubmitted">
                  <!-- <input
                    id="checkbox"
                    v-model="checked"
                    type="checkbox"
                    @click="getFreePrivate()"
                  >
                  <br>
                  <label for="checkbox"> {{ $t(" PrivateUser") }}</label> -->

                  <b-form-checkbox
                    id="checkbox"
                    v-model="checked"
                    type="checkbox"
                    @input="getFreePrivate()"
                  >
                    {{ $t('PrivateUser') }}
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
          <div style="margin-left: 15px">
            <b-button-group class=" mb-2" />
            <div class="mt-2">
              <b-button
                v-show="tabIndex ==0"
                :disabled="formSubmitted || !$v.store.required || !$v.time1.required || !$v.time2.required"
                type="button"
                variant="none"
                class="buttonSubmit buttonNext"
                style="margin-bottom: 10px;"
                @click="tabIndex++, replaceShow(); getFreeRegularTrucks(), click();"
              >
                {{ $t("EmployeeNext") }}
              </b-button>
              <b-button
                v-show="tabIndex ==1"
                type="button"
                variant="none"
                class="buttonSubmit butoniSubmit"
                style="margin-bottom: 10px;"
                @click="checked ? onSubmitAddExchangeTruck() : onSubmitAddTruck()"
              >
                {{ $t("ExchangeEmployeesSubmit") }}
              </b-button>
              <b-button
                type="button"
                variant="danger"
                class="buttonCancel"
                style="margin-right: 15px; margin-bottom: 10px;"
                @click="onCancel"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </div>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import {
  required,
} from 'vuelidate/lib/validators';

export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      isChecked: false,
      isCheckedToday: false,
      isCheckedMorning: false,
      isCheckedAfternoon: false,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee'],
      replace: false,
      time1: null,
      time2: null,
      formSubmitted: false,
      checked: false,
      storeGLN: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      personalInfoForm: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        houseNumber: '',
        street: '',
        country: '',
        passport: '',
        aufenthaltsbewilligung: '',
        ahv: '',
        email: '',
        role: '',
      },
      storeId: null,
      clicked: false,
      truck: {
        storeId: null,
        startDate: '',
        endDate: '',
      },
      data: {
        destinationStoreId: null,
        sourceStoreId: null,
        startShift: null,
        endShift: null,
        exchangeTruckId: null,
      },
      privateUser: {
        privateEmployeeUserId: null,
        sourceStoreId: null,
        startShift: null,
        endShift: null,
        exchangeTruckId: null,
      },
      store: null,
      truckSelected: null,
      destination: null,
      destinationId: null,
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
    };
  },
  validations: {
    store: {
      required,
    },
    destination: {
      required,
    },
    time1: {
      required,
    },
    time2: {
      required,
    },
    truckSelected: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getFiliales',
      'getFreeRegularTrucksByStore',
      'getFreePrivateUsers',
    ]),
    isValidDate() {
      if (!this.time1 || !this.time2) {
        return true; // allow empty dates
      }
      const start = moment(this.time1, 'DD-MM-YYYY HH:mm').toDate();
      const end = moment(this.time2, 'DD-MM-YYYY HH:mm').toDate();
      return start <= end;
    },
    destinationOptions() {
      return this.getFiliales.filter((filiale) => {
        return filiale.storeId !== (this.store ? this.store.storeId : null)
      })
    },
    isButtonDisabled() {
      return !this.time1 || !this.time2;
    },
    datesValid() {
      return this.time2 > this.time1;
    },
    // isValidDate() {
    //   if (!this.time1 || !this.time2) {
    //     return true; // allow empty dates
    //   }
    //   const start = moment(this.time1, 'DD-MM-YYYY HH:mm').toDate();
    //   const end = moment(this.time2, 'DD-MM-YYYY HH:mm').toDate();
    //   return start <= end;
    // },
    // isValidDate() {
    //   if (!this.time1 || !this.time2) {
    //     return true; // allow empty dates
    //   }
    //   const start = moment(this.time1, 'DD-MM-YYYY HH:mm').toDate();
    //   const end = moment(this.time2, 'DD-MM-YYYY HH:mm').toDate();
    //   return start <= end && !moment(start).isSame(end);
    // },
  },
  watch: {
    time1(value) {
      // console.log('location', moment.tz.guess())
      const res = moment.tz.guess();
      const value2 = moment(value, 'DD-MM-yyyyTHH:mm:ss');
      const formattedDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.truck.startDate = formattedDate;
      this.data.startShift = formattedDate;
      this.privateUser.startShift = formattedDate
      // this.time2 = value
    },
    time2(value) {
      const res = moment.tz.guess();
      const value2 = moment(value, 'DD-MM-yyyyTHH:mm:ss');
      const formattedDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.truck.endDate = formattedDate;
      this.data.endShift = formattedDate;
      this.privateUser.endShift = formattedDate
    },
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.time2 = null;
        this.isChecked = false;
        this.isCheckedMorning = false;
        this.isCheckedAfternoon = false;
        this.isCheckedToday = false;
      }
    },
    store: {
      handler(value) {
        if (value.storeId == '') {
          this.truck.storeId = null;
        }
        this.truck.storeId = value.storeId;
        this.storeId = value.storeId;
        this.data.sourceStoreId = value.storeId
        this.privateUser.sourceStoreId = value.storeId
        // this.getFreeRegularTrucks(value);
        // console.log('ppp', this.truck.storeId);
      },
    },
    destination: {
      handler(value) {
        if (value.storeId == '') {
          this.data.destinationStoreId = null;
        }
        this.privateUser.privateEmployeeUserId = value.userId
        this.data.destinationStoreId = value.storeId;
        // if (this.checked) {
        // }
        // this.getFreeRegularTrucks(value);
        // console.log('ppp', this.truck.storeId);
      },
    },
    truckSelected: {
      handler(value) {
        // console.log('rr', value)
        this.data.exchangeTruckId = value.truckId
        this.privateUser.exchangeTruckId = value.truckId
      },
    },

    // isValidDate(newValue, oldValue) {
    //   if (newValue !== oldValue && !newValue) {
    //     this.time2 = null;
    //   }
    // },
    // isValidDate(newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     const start = moment(this.time1, 'DD-MM-YYYY HH:mm').toDate();
    //     const end = moment(this.time2, 'DD-MM-YYYY HH:mm').toDate();
    //     if (start > end) {
    //       this.time2 = null;
    //     }
    //   }
    // },
    // 'truck.startDate': {
    //   handler(value) {
    //     // this.getFreeRegularTrucks(value);
    //     this.data.startShift = moment(value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm');
    //     this.privateUser.startShift = moment(value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm');
    //   },
    // },
    // 'truck.endDate': {
    //   handler(value) {
    //     this.data.endShift = moment(value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm');
    //     this.privateUser.endShift = moment(value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm');
    //     // this.getFreeRegularTrucks(value);
    //   },
    // },
  },
  methods: {
    ...mapActions(['assignExchangeFreeRegularTrucks', 'loadFreePrivateUser', 'loadFreeRegularEmployee', 'loadFilialePagination']),
    validateState(name) {
      const { $dirty, $error } = this.$v.personalInfoForm[name];
      return $dirty ? !$error : null;
    },
    // removeSelectedFiliale(selectedFiliale) {
    //   this.$store.commit('REMOVE_FREE_FILIALE', selectedFiliale);
    // },
    getFreeRegularTrucks() {
      this.assignExchangeFreeRegularTrucks({
        storeId: this.truck.storeId,
        startShift: this.truck.startDate,
        endShift: this.truck.endDate,
        // endShift: moment(this.truck.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      })
    },
    getFreePrivate() {
      this.loadFreePrivateUser({
        storeId: this.storeId,
        startShift: this.truck.startDate,
        endShift: this.truck.endDate,
        includeTeamLeaders: false,
        includeManager: true,
      })
    },
    setDefaultTime(value) {
      if (value == true) {
        this.time1 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 08:00`;
        this.time2 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 17:00`;
        this.truck.startDate = `${moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:ss.sssZ')}`;
        this.truck.endDate = `${moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:ss.sssZ')}`;
        this.isCheckedMorning = false;
        this.isCheckedAfternoon = false;
        this.isCheckedToday = false;
      } else {
        // this.truck.startDate = '';
        // this.truck.endDate = '';
        // this.time1 = null;
        // this.time2 = null;
      }
    },
    setToday(value) {
      if (value == true) {
        this.truck.startDate = `${moment().format('YYYY-MM-DDT08:00:ss.sssZ')}`;
        this.time1 = `${moment().format('DD-MM-YYYY')} 08:00`;
        this.time2 = `${moment().format('DD-MM-YYYY')} 17:00`;
        this.truck.endDate = `${moment().format('YYYY-MM-DDT17:00:ss.sssZ')}`;
        this.isCheckedMorning = false;
        this.isCheckedAfternoon = false;
        this.isChecked = false;
      } else {
        // this.truck.startDate = '';
        // this.truck.endDate = '';
        // this.time1 = null;
        // this.time2 = null;
      }
    },
    setMorning(value) {
      if (value == true) {
        if (this.time1 !== null) {
          this.time1 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 08:00`;
          this.time2 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 12:30`;
          this.truck.startDate = `${moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:ss.sssZ')}`;
          this.truck.endDate = `${moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT12:30:ss.sssZ')}`;
        } else {
          this.truck.startDate = `${moment().format('YYYY-MM-DDT08:00:ss.sssZ')}`;
          this.time1 = `${moment().format('DD-MM-YYYY')} 08:00`;
          this.truck.endDate = `${moment().format('YYYY-MM-DDT12:30:ss.sssZ')}`;
          this.time2 = `${moment().format('DD-MM-YYYY')} 12:30`;
        }
        this.isCheckedAfternoon = false;
        this.isChecked = false;
        this.isCheckedToday = false;
      } else {
        // this.truck.startDate = '';
        // this.truck.endDate = '';
        // this.time1 = null;
        // this.time2 = null;
      }
    },
    setAfternoon(value) {
      if (value == true) {
        if (this.time1 !== null) {
          this.time1 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 12:31`;
          this.time2 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 17:00`;
          this.truck.startDate = `${moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT12:31:ss.sssZ')}`;
          this.truck.endDate = `${moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:ss.sssZ')}`;
        } else {
          this.truck.startDate = `${moment().format('YYYY-MM-DDT12:31:ss.sssZ')}`;
          this.time1 = `${moment().format('DD-MM-YYYY')} 12:31`;
          this.time2 = `${moment().format('DD-MM-YYYY')} 17:00`;
          this.truck.endDate = `${moment().format('YYYY-MM-DDT17:00:ss.sssZ')}`;
        }
        this.isCheckedMorning = false;
        this.isChecked = false;
        this.isCheckedToday = false;
      } else {
        // this.truck.startDate = '';
        // this.truck.endDate = '';
        // this.time1 = null;
        // this.time2 = null;
      }
    },
    shownReq() {
      this.getFreeRegularTrucks();
      this.getFreePrivate();
      this.loadFilialePagination({
        status: true,
        pageSize: 100,
        pageNumber: 1,
      })
    },
    onSubmitAddTruck() {
      this.formSubmitted = true
      this.$v.store.$touch();
      if (this.$v.store.$anyError) {
        return;
      }
      this.$v.destination.$touch();
      if (this.$v.destination.$anyError) {
        return;
      }
      this.$v.truckSelected.$touch();
      if (this.$v.truckSelected.$anyError) {
        return;
      }
      this.$emit('click', this.data);
      this.$refs.modal.hide();
      this.clicked = false
      this.onReset()
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onSubmitAddExchangeTruck() {
      this.formSubmitted = true
      this.$v.store.$touch();
      if (this.$v.store.$anyError) {
        return;
      }
      this.$v.destination.$touch();
      if (this.$v.destination.$anyError) {
        return;
      }
      this.$emit('clickk', this.privateUser);
      this.$refs.modal.hide();
      this.clicked = false
      this.onReset()
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    replaceShow() {
      this.replace = !this.replace;
      // this.selectedItemId = itemId;
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
      this.replace = false;
    },
    click() {
      this.clicked = !this.clicked
    },
    onReset() {
      this.store = '';
      this.destination = '';
      this.time1 = '';
      this.time2 = ''
      this.truckSelected = '';
      this.tabIndex = 0
      this.clicked = false;
      this.formSubmitted = false
      this.isChecked = false
      this.isCheckedToday = false;
      this.isCheckedMorning = false;
      this.isCheckedAfternoon = false;
      // },
    },
    // clearDestinationInputs() {
    //   this.destination = null;
    //   this.truckSelected = null;
    //   this.checked = false;

  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form2 {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
