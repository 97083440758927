<template>
  <div>
    <b-modal
      id="cancel-exchange-truck"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ExchTrucksCancel')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <!-- @shown="" -->
      <p v-if="getRoutersForTrucks.length !==0 && privateUserr == null">
        {{ $t("ExchTrucksRoutes") }}
      </p>
      <p v-else>
        {{ $t("ExchTrucksExch") }}
      </p>
      <div v-if="privateUserr == null">
        <div
          v-for="item in getRoutersForTrucks"
          :key="item.tourId"
          style="
          background-color: gainsboro;
          border-radius: 8px;
          padding: 10px;
          min-height: 56px;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

        "
        >
          <div
            style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;"
          >
            <b-form-group
              id="input-group-8"
              :label="$t('ExchTrucksRN')"
              label-for="input-25"
            >
              {{ item.tourName }}
            </b-form-group>
            <b-form-group
              id="input-group-8"
              :label="$t('ExchTrucksEns')"
              label-for="input-25"
            >
              {{ dateModified2(item.endShift) }}
            </b-form-group>
            <b-button
              type="button"
              variant="none"
              style="margin-left: 10px"
              class="buttonSubmit"
              @click="fetchTrucks(item.storeGLN, item.endShift), replaceShow(), getRouteID(item.tourId),selectedItemId = item.tourId"
            >
              {{ $t("ExchTrucksReplace") }}
            </b-button>
          </div>
          <b-form-group
            v-if="replace == true && item.tourId === selectedItemId"
            :id="item.tourId"
            style="width: 100%"
            :label="$t('ExchTrucksSFT')"
            label-for="input-25"
          >
            <vue-select
              id="input-8"
              v-model="truckSelected"
              :options="getFreeTrucks"
              :value="truckId"
              placeholder="Select"
              label="registrationPlate"
            />
            <b-button
              style="margin-top: 10px"
              type="button"
              variant="none"
              class="buttonSubmit"
              @click="editTruckToRoute(truckSelected.truckId)"
            >
              {{ $t("ExchTrucksExchange") }}
            </b-button>
          </b-form-group>

        </div>
      </div>
      <div style="margin-top: 20px">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 0px"
          class="buttonSubmit"
          :disabled="getRoutersForTrucks.length !== 0 && privateUserr == null"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  components: {
    // flatPickr,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['item', 'item2', 'destinationStoreGln', 'privateUserr'],
  data() {
    return {
      truckIDD: '',
      routeID: '',
      storeeID: null,
      deactivate: {
        truckId: null,
        status: false,
      },
      endshift: null,
      truckSelected: '',
      selectedItemId: null,
      pageSize: 10,
      pageNumber: 1,
      truckId: '',
      exchangeId: '',
      replace: false,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee'],
      personalInfoForm: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        houseNumber: '',
        street: '',
        country: '',
        passport: '',
        aufenthaltsbewilligung: '',
        ahv: '',
        email: '',
        role: '',
      },
      Selected: {
        brandName: '',
        modelName: '',
        variantName: '',
        registrationPlate: '',
        firstRegistration: '',
        insuranceExpirationDate: '',
        storeId: null,
      },
      store: null,
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getRoutersForTrucks', 'getFreeTrucks']),
  },
  watch: {
    store: {
      handler(value) {
        if (value.storeId == '') {
          this.Selected.storeId = null;
        }
        this.truck.storeId = value.storeId;
      },
    },
    item: {
      handler(value) {
        this.truckId = value;
      },
      immediate: true,
    },
    item2: {
      handler(value) {
        this.exchangeId = value
      },
    },


  },

  mounted() {
    this.truckIDD = this.item;
    // this.routes()
  },
  methods: {
    ...mapActions(['loadTrucksPaginationForDeactive', 'exchangeTruckInATour', 'loadToursForTrucks', 'loadFreeTrucks']),
    validateState(name) {
      const { $dirty, $error } = this.$v.personalInfoForm[name];
      return $dirty ? !$error : null;
    },
    fetchTrucks(storeGLNn, endShiftt) {
      this.loadFreeTrucks({
        storeGLN: storeGLNn,
        startShift: this.dateModified(new Date().toJSON().slice(0, 10).replace(/-/g, '-')),
        endShift: this.dateModified(endShiftt),
      }).then((res) => {
        console.log(res);
      });
    },
    getRouteID(value) {
      this.routeID = value
    },
    replaceShow(itemId) {
      this.replace = !this.replace;
      this.selectedItemId = itemId;
    },

    async editTruckToRoute(value) {
      await this.exchangeTruckInATour({
        tourId: this.routeID,
        truckId: this.item,
        newTruckId: value,
        successCallback: () => {
          this.loadToursForTrucks({
            truckId: this.item,
            destinationStoreGln: this.destinationStoreGln,
            startShift: this.dateModified(new Date().toJSON().slice(0, 10).replace(/-/g, '-')),
          })
          this.loadTrucksPaginationForDeactive({
            storeId: 'UNASSIGNED',
            status: true,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
          this.loadFreeTrucks({
            storeId: this.storeeID,
            startShift: this.dateModified(new Date().toJSON().slice(0, 10).replace(/-/g, '-')),
            endShift: this.endshift,
          })
        },
      })
    },

    onSubmit() {
      this.$emit('click', this.exchangeId);
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    dateModified(xdate) {
      return moment.utc(xdate).format('YYYY-MM-DD HH:mm');
    },
    dateModified2(xdate) {
      return moment.utc(xdate).format('DD-MM-YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
